import * as log                        from 'fizyr-node-utils/lib/logger';
import { BsonRpcPeer }                 from 'fizyr-node-utils/lib/bson_rpc_peer';
import { BrowserWebsocket, wsConnect } from 'fizyr-node-utils/lib/browser_websocket';
import { MessageHandler }              from 'fizyr/types';


export class WebsocketClient  {
	private _peer?           : BsonRpcPeer;
	private _connected       : boolean  = false;
	private _reconnect_timer : any      = null;
	private _logger          : log.Logger;
	private _message_handler : MessageHandler;

	constructor(message_handler: MessageHandler, logger?: log.Logger) {
		this._message_handler = message_handler;
		this._logger = logger || log.nullLogger();
		this.connect();
	}

	private async onSocketOpen(): Promise<void> {
		this._logger.info("App::onSocketOpen: connected to websocket server");
		this._connected = true;
		await this.ping();
	}

	private onSocketClose(): void {
		this._logger.info("App::onSocketClose: disconnected from websocket server");
		this._connected = false;
		this.startConnectTimeout();
	}

	private connect(): void {
		if (this._connected) {
			this._logger.warn("App::connect: tried to connect while connection was already open");
			return;
		}

		this._logger.debug("App::connect: starting connection attempt to websocket server");

		try {
			const stream = new BrowserWebsocket(wsConnect('/'));
			this._peer   = new BsonRpcPeer(stream, (peer: any, message: any) => this._message_handler(peer, message), this._logger);
			this._peer.on_open.once(()  => this.onSocketOpen());
			this._peer.on_close.once(() => this.onSocketClose());
		} catch (err) {
			this._logger.debug(`App::connect: connection attempt to websocket server failed with error: ${err}`);
			this.startConnectTimeout();
		}
	}

	private startConnectTimeout() {
		if (this._reconnect_timer) {
			this._logger.warn("App::startConnectTimeout:: reconnect timer already started: ", this._reconnect_timer);
			return;
		}

		this._reconnect_timer = window.setTimeout(() => {
			this._reconnect_timer = null;
			this.connect();
		}, 1000);
		this._logger.info("App::startConnectTimeout:: started reconnect timeout: ", this._reconnect_timer);
	}

	public async ping() {
		if (!this._connected || !this._peer) throw Error("not connected to websocket server");
		await this._peer.sendRequest({command: 'ping'});
	}

	public async getStatisticsData(dataset_key: string, user_id: string): Promise<any> {
		if (!this._connected || !this._peer) throw Error("not connected to websocket server");
		const data =  await this._peer.sendRequest(
			{
				command: 'get-statistics-data',
				meta_data: {
					dataset_key: dataset_key,
					user_id: user_id,
				},
			});

		return data;
	}
}
