import * as dc               from 'dc';
import * as d3array          from 'd3-array';
import * as d3scale          from 'd3-scale';
import * as d3scalechromatic from 'd3-scale-chromatic';
import * as crossfilter      from 'crossfilter';
import * as cookie           from 'js-cookie';

import * as browser_util  from 'fizyr-node-utils/lib/browser_util';
import { BsonRpcPeer }    from 'fizyr-node-utils/lib/bson_rpc_peer';

import { Message }         from 'fizyr/types';
import { WebsocketClient } from './websocket';


export enum XAxisFactor {
	KILO = 1000,
	MEGA = 1000000,
	GIGA = 1000000000,
}

interface ChartOptions {
	width: number;
	height: number;
	label: string;
	x_axis_label: string;
	y_axis_label?: string;
	bin_number?: number;
	factor?: XAxisFactor;
}


dc.config.defaultColors(<string []>d3scalechromatic.schemeDark2);

/**
 * Handler for getStatisticsData response.
 *
 */
function onStatisticsData(message: Message, chart: dc.BarChart, chart_options: ChartOptions) {
	const statistics = message.data;

	// TODO: Currently it only displays main segmentations. Support for submask in future.
	const index_segmentation = statistics.polygon._index.segmentation;
	const data = statistics.polygon._items[index_segmentation].value;

	const chart_title = document.getElementById('statistics-chart-title');
	if (chart_title) {
		chart_title.innerHTML = "Segmentation statistics";
	}
	drawChart(data, chart, chart_options);
}

/**
 * Draw chart on given chart element.
 *
*/
function drawChart(data: any, chart: dc.BarChart, chart_options: ChartOptions) {
	// Calculate min,max and range. If a factor is used, divide by factor.

	const min = (chart_options.factor ? Math.round(Number(d3array.min(data)) / chart_options.factor) : d3array.min(data));
	// TODO: domain from 0 to image_resolution
	const max = (chart_options.factor ? Math.round(Number(d3array.max(data)) / chart_options.factor) : d3array.max(data));
	const range = Number(max) - Number(min);

	// Get x_axis domain
	const x_axis = d3scale.scaleLinear().domain([0, Number(max)]);

	// Calculate dataset grouped by bin width
	const number_of_bins = (chart_options.bin_number ? chart_options.bin_number : 100);
	const bin_width = Math.round(range / number_of_bins);
	const dataset = crossfilter(data);
	const dimensions = dataset.dimension((d: any) => (chart_options.factor ? Math.round(d / chart_options.factor) : d));
	const group = dimensions.group((d: any) => bin_width * Math.floor(d / bin_width));

	// Define chart parameters
	chart.width(chart_options.width)
		.height(chart_options.height)
		.x(x_axis)
		.brushOn(false)
		.xAxisLabel(chart_options.x_axis_label)
		.yAxisLabel((chart_options.y_axis_label ? chart_options.y_axis_label : ""))
		.dimension(dimensions)
		.group(group);

	chart.title((d: any) => `${d.value} annotations with area in: ${d.key - bin_width} - ${d.key}.`);
	chart.xUnits(() => number_of_bins);

	// Render chart
	chart.render();
}

/**
 * Run the websocket lcient and event listeners
 *
 */
function run(dataset: string, user: string, chart: dc.BarChart) {
	const websocket_message_handler = async(_peer: BsonRpcPeer, message: Message): Promise<any> => {
		if (message.command === 'statistics-data') {
			return onStatisticsData(
				message,
				chart,
				{
					width: 500,
					height: 400,
					label: "",
					x_axis_label: "Area in kilopixels",
					bin_number: 100,
					factor: XAxisFactor.KILO,
				},
			);
		}
		return null;
	};

	const websocket = new WebsocketClient(websocket_message_handler);

	// TODO: Button should only do action if it is not in that tab
	const button = browser_util.findElement('annotation_statistics-label');

	button.addEventListener('click', async() => {
		// Already clicked the tab button
		if (button.tabIndex === 0) return;

		onStatisticsData(
			await websocket.getStatisticsData(dataset, user),
			chart,
			{
				width: 500,
				height: 400,
				label: "",
				x_axis_label: "Area in kilopixels",
				bin_number: 100,
				factor: XAxisFactor.KILO,
			},
		);
	});
}

/**
 * Initialize elements on page.
 *
 */
function initialize() {
	// TODO: 
	const dataset = cookie.get('dataset');
	if (!dataset) {
		console.log("Can't find dataset key");
		return;
	}
	const user = cookie.get('user_id');
	if (!user) {
		console.log("Can't find user key");
		return;
	}
	const chart = dc.barChart('#statistics-chart');
	if (!chart) {
		console.log("No Chart found");
	}
	run(dataset, user, chart);
}

initialize();
